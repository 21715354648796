import { Box } from "@mui/material";
import React from "react";
import imgHeadline from "../images/headline.svg";
import imgHeadlineXL from "../images/headline_xl_2.svg";
import imgCursor from "../images/cursor.svg";
import imgCursorXL from "../images/cursor_xl.svg";
import imgTagline from "../images/tagline_2.svg";
import { motion } from "framer-motion";

const DesktopSplash = () => {
  return (
    <>
      <Box
        sx={{
          position: "relative",
          width: 510,
          height: 174,
          top: 268,
          left: 24,
          display: { xs: "none", md: "block", xl: "none" },
        }}
      >
        <Box component="img" src={imgHeadline} alt="headline" sx={{ position: "relative", zIndex: 1 }} />
        <motion.div
          initial={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "189px",
            height: "96px",
            zIndex: 3,
          }}
          animate={{
            x: [0, 390, 0, 500],
            y: [0, 0, 100, 100],
          }}
          transition={{ duration: 3, delay: 0.51 }}
        >
          <Box
            component="img"
            src={imgCursor}
            alt="cursor"
            sx={{ position: "absolute", top: 0, left: 0, height: 72 }}
          />
        </motion.div>
        <motion.div
          id="here"
          initial={{
            width: "510px",
            backgroundColor: "#F6F4FA",
            position: "absolute",
            height: "72px",
            zIndex: "2",
            top: 0,
            right: 0,
          }}
          animate={{
            width: 126,
          }}
          transition={{ duration: 0.99, delay: 0.495 }}
        />
        <motion.div
          initial={{
            width: "509px",
            backgroundColor: "#F6F4FA",
            position: "absolute",
            height: "76px",
            zIndex: "2",
            bottom: 0,
            right: 19,
          }}
          animate={{
            width: 0,
          }}
          transition={{ duration: 0.98, delay: 2.5 }}
        />
        <motion.div
          initial={{
            opacity: 0,
            position: "absolute",
            left: -10,
            top: 208,
          }}
          animate={{
            opacity: 1,
            left: 0,
          }}
          transition={{ duration: 1, delay: 3, ease: "easeInOut" }}
        >
          <Box component="img" src={imgTagline} alt="headline" />
        </motion.div>
      </Box>

      <Box
        sx={{
          position: "absolute",
          width: 635,
          height: 224,
          top: 384,
          left: 24,
          display: { xs: "none", xl: "block" },
        }}
      >
        <Box
          component="img"
          src={imgHeadlineXL}
          alt="tagline"
          sx={{ position: "relative", zIndex: 1, width: 635, height: 224 }}
        />
        <motion.div
          initial={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "189px",
            height: "96px",
            zIndex: 3,
          }}
          animate={{
            x: [0, 495, 0, 642],
            y: [0, 0, 130, 130],
          }}
          transition={{ duration: 3, delay: 0.5 }}
        >
          <Box
            component="img"
            src={imgCursorXL}
            alt="cursor"
            sx={{ position: "absolute", top: 0, left: 0, width: 190 }}
          />
        </motion.div>
        <motion.div
          initial={{
            width: "635px",
            backgroundColor: "#F6F4FA",
            position: "absolute",
            height: "130px",
            zIndex: "2",
            top: 0,
            right: 0,
          }}
          animate={{
            width: 136,
          }}
          transition={{ duration: 1, delay: 0.495 }}
        />
        <motion.div
          initial={{
            width: "640px",
            backgroundColor: "#F6F4FA",
            position: "absolute",
            height: "130px",
            zIndex: "2",
            bottom: 0,
            right: 0,
          }}
          animate={{
            width: 0,
          }}
          transition={{ duration: 0.97, delay: 2.5 }}
        />
        <motion.div
          initial={{
            opacity: 0,
            position: "absolute",
            left: -10,
            top: 300,
          }}
          animate={{
            opacity: 1,
            left: 0,
          }}
          transition={{ duration: 1, delay: 3, ease: "easeInOut" }}
        >
          <Box component="img" src={imgTagline} alt="headline" width="600px" />
        </motion.div>
      </Box>
    </>
  );
};

export default DesktopSplash;
