import { Box } from "@mui/material";
import React from "react";
import { motion } from "framer-motion";
import MobileSplash from "../components/MobileSplash";
import DesktopSplash from "../components/DesktopSplash";
import DesktopBanners from "../components/DesktopBanners";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import imgBrand from "../images/brand.svg";
import imgGetDemo from "../images/get_demo.svg";

const HomePage = () => {
  return (
    <>
      <Helmet>
        <title>AI-Powered Software for Medical Aesthetic Practices | Bonsai Health</title>
      </Helmet>
      <motion.div
        initial={{
          top: "-50px",
          position: "absolute",
          opacity: 0,
          width: "100%",
          zIndex: 10,
        }}
        animate={{ top: 0, opacity: 1 }}
        transition={{ duration: 0.5, ease: "easeInOut", delay: 4 }}
      >
        <Box
          sx={{
            backgroundColor: "#fff",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              height: 80,
              maxWidth: 1920,
              width: "100%",
              px: 3,
            }}
          >
            <Link to="/" className="nav-logo-link">
              <Box component="img" src={imgBrand} alt="logo" sx={{ position: "relative", height: { xs: 28 } }} />
            </Link>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Link to="mailto:sales@bonsaihealth.com" className="nav-link">
                sales@bonsaihealth.com
              </Link>
              <Link to="/demo">
                <Box
                  component="img"
                  src={imgGetDemo}
                  alt="Get Demo"
                  sx={{ height: 40, width: "auto", display: "flex" }}
                />
              </Link>
            </Box>
          </Box>
        </Box>
      </motion.div>
      <Box
        sx={{
          overflow: "hidden",
          height: "100%",
          position: "relative",
          maxWidth: 1920,
          minHeight: 900,
          marginX: "auto",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            width: { xs: "100%", md: 800 },
            minWidth: { xs: "100%", md: 800 },
            padding: { xs: "46px 0 0 16px", md: "56px 0 0 0" },
          }}
        >
          <MobileSplash />
          <DesktopSplash />
        </Box>
        <Box sx={{ position: "relative", width: "100%" }}>
          <DesktopBanners />
        </Box>
      </Box>
    </>
  );
};

export default HomePage;
