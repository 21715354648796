import { Box } from "@mui/material";
import { motion } from "framer-motion";
import imgCursorMb from "../images/cursor_mb.svg";
import imgHeadlineMb from "../images/headline.svg";
import React from "react";
import imgTaglineMb from "../images/tagline_2.svg";
import imgBannerOne from "../images/banner_mb_1.webp";
import imgBannerTwo from "../images/banner_mb_2.webp";
import imgBannerThree from "../images/banner_mb_3.webp";

const MobileSplash = () => {
  return (
    <Box
      sx={{
        display: { xs: "flex", md: "none" },
        flexDirection: "column",
        position: "relative",
        mt: 6,
      }}
    >
      <Box sx={{ position: "relative", width: 250, height: 88 }}>
        <motion.div
          initial={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "189px",
            height: "55px",
            zIndex: 3,
          }}
          animate={{
            x: [0, 200, 0, 254],
            y: [0, 0, 42, 42],
          }}
          transition={{ duration: 3, delay: 0.5 }}
        >
          <Box
            component="img"
            src={imgCursorMb}
            height="48px"
            alt="cursor"
            sx={{ position: "absolute", top: 0, left: 0 }}
          />
        </motion.div>
        <motion.div
          initial={{
            width: "248px",
            backgroundColor: "#F6F4FA",
            position: "absolute",
            height: "38px",
            zIndex: "2",
            top: 0,
            right: 0,
          }}
          animate={{
            width: 45,
          }}
          transition={{ duration: 1, delay: 0.5 }}
        />
        <motion.div
        id="here"
          initial={{
            width: "250px",
            backgroundColor: "#F6F4FA",
            position: "absolute",
            height: "38px",
            zIndex: "2",
            bottom: 0,
            right: 0,
          }}
          animate={{
            width: 0,
          }}
          transition={{ duration: 1, delay: 2.5 }}
        />
        <Box component="img" src={imgHeadlineMb} alt="tagline" sx={{ position: "relative", zIndex: 1, width: 250 }} />
      </Box>
      <motion.div
        initial={{
          opacity: 0,
          position: "relative",
          top: "32px",
          left: "-20px",
        }}
        animate={{
          opacity: 1,
          left: 0,
        }}
        transition={{ duration: 1, delay: 2.5, ease: "easeInOut" }}
      >
        <Box component="img" src={imgTaglineMb} alt="headline" width="300px" />
      </motion.div>
      <Box sx={{ display: "flex", justifyContent: "center", position: "relative", top: 70, right: 16 }}>
        <motion.div
          initial={{ top: 100, opacity: 0, position: "relative" }}
          animate={{ top: 17, opacity: 1 }}
          transition={{ duration: 1.75, ease: "easeInOut", delay: 2 }}
        >
          <Box component="img" src={imgBannerOne} alt="banner 1" sx={{ width: 126, mx: 0.5 }} />
        </motion.div>
        <motion.div
          initial={{ top: -50, opacity: 0, position: "relative" }}
          animate={{ top: 0, opacity: 1 }}
          transition={{ duration: 1.75, ease: "easeInOut", delay: 2 }}
        >
          <Box component="img" src={imgBannerTwo} alt="banner 2" sx={{ width: 126, mx: 0.5 }} />
        </motion.div>
        <motion.div
          initial={{ top: 100, opacity: 0, position: "relative" }}
          animate={{ top: 38, opacity: 1 }}
          transition={{ duration: 1.75, ease: "easeInOut", delay: 2 }}
        >
          <Box component="img" src={imgBannerThree} alt="banner 3" sx={{ width: 126, mx: 0.5 }} />
        </motion.div>
      </Box>
    </Box>
  );
};

export default MobileSplash;
